import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AuthModule } from './modules/auth/auth.module';
import { ActionsService } from './services/actions.service';
import { AuthService } from './services/auth.service';
import { DocumentsService } from './services/documents.service';
import { DriversService } from './services/drivers.service';
import { RoutingStateService } from './services/routing-state.service';
import { VehiclesService } from './services/vehicles.service';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LandingPageComponent,
    SidebarComponent,
  ],
  imports: [BrowserModule, AuthModule, AppRoutingModule],
  providers: [
    AuthService,
    RoutingStateService,
    ActionsService,
    DriversService,
    VehiclesService,
    DocumentsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
