import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  registerSuccess = false;
  form: FormGroup;
  registering = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {}

  onSubmit(form): void {
    this.registering = true;
    // Process checkout data here
    if (this.form.valid) {
      const observable = this.authService.signUp(form.email, form.password);
      observable.subscribe(
        (iSignUpResult) => {
          this.authService.signupUser = iSignUpResult.user;
          this.registerSuccess = true;
          this.router.navigateByUrl('/confirm-email');
          // const x2 = this.authService.signUp(form.email, form.password);
          // x2.subscribe(
          //   (x3) => {
          //     this.authService.signupUser = x3.user;
          //     this.registerSuccess = true;
          //     this.router.navigateByUrl('/confirm-email');
          //   },
          //   (error) => {
          //     this.registering = false;
          //     alert('This email is already used. Please use another email.');
          //   }
          // );
        },
        (error) => {
          this.registering = false;
          alert('This email is already used. Please use another email.');
        }
      );
    } else {
      this.registering = false;
      this.form.markAllAsTouched();
    }
  }

  get email(): AbstractControl {
    return this.form.controls.email;
  }
  get password(): AbstractControl {
    return this.form.controls.password;
  }
}
