<div class="container">
  <div class="row-gap-50"></div>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <h2>Sign In</h2>
      <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
        <div>
          <label for="email">Email: </label>
          <input
            id="email"
            class="form-control"
            type="email"
            formControlName="email"
            required
          />
          <div
            *ngIf="email.invalid && email.dirty && email.touched"
            class="alert alert-danger"
          >
            <div *ngIf="email.errors.required">Email is required.</div>
            <div *ngIf="email.errors.email">Email must be a valid email.</div>
          </div>
        </div>
        <div>
          <label for="password">Password: </label>
          <input
            id="password"
            class="form-control"
            formControlName="password"
            type="password"
            required
          />
          <div
            *ngIf="password.invalid && password.dirty && password.touched"
            class="alert alert-danger"
          >
            <div *ngIf="password.errors.required">Password is required.</div>
          </div>
        </div>
        <br />
        <button
          type="submit"
          [disabled]="!form.valid || submitting"
          class="btn btn-primary btn-block"
        >
          <ng-container *ngIf="submitting"
            ><img
              class="loading_in_btn"
              src="/assets/img/loading_icon.gif" /></ng-container
          ><ng-container *ngIf="!submitting">Sign-in</ng-container>
        </button>
        <a routerLink="../forgot-password">Forgot password</a>
      </form>
    </div>
  </div>
</div>
