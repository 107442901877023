import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'documents',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/documents/documents.module').then(
        (m) => m.DocumentsModule
      ),
  },
  {
    path: 'actions',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/actions/actions.module').then((m) => m.ActionsModule),
  },
  {
    path: 'drivers',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/drivers/drivers.module').then((m) => m.DriversModule),
  },
  {
    path: 'vehicles',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/vehicles/vehicles.module').then(
        (m) => m.VehiclesModule
      ),
  },
  {
    path: 'account',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./modules/info/info.module').then((m) => m.InfoModule),
  },
  { path: '', component: LandingPageComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '/' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
