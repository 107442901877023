import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  title: string;
  subtitle: string;
  description: string;
  constructor() {
    this.title = 'TFleet';
    this.subtitle = 'Just a simple fleet management system';
    this.description = '';
  }

  ngOnInit(): void {}
}
