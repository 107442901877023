<div class="bg-light border-right" id="sidebar-wrapper">
  <div class="row-gap-57"></div>
  <!-- <div class="sidebar-heading text-right"></div> -->
  <div class="list-group list-group-flush">
    <a
      class="list-group-item list-group-item-action bg-light"
      (click)="toggle()"
    >
      Toggle Sidebar<button class="btn btn-sm btn-default float-right">
        <i class="fas fa-exchange-alt"></i>
      </button>
    </a>
    <hr />
    <a
      routerLink="/"
      routerLinkActive
      class="list-group-item list-group-item-action bg-light"
      >Dashboard
      <button class="btn btn-sm btn-default float-right nav-icon">
        <i class="fas fa-tachometer-alt"></i></button
    ></a>
    
    <a
      routerLink="/vehicles"
      routerLinkActive
      class="list-group-item list-group-item-action bg-light"
      >Vehicles
      <button class="btn btn-sm btn-default float-right nav-icon">
        <i class="fas fa-truck"></i></button
    ></a>
    <a
      routerLink="/drivers"
      routerLinkActive
      class="list-group-item list-group-item-action bg-light"
      >Drivers
      <button class="btn btn-sm btn-default float-right nav-icon">
        <i class="fas fa-users"></i></button
    ></a>
    <a
      routerLink="/actions"
      routerLinkActive
      class="list-group-item list-group-item-action bg-light"
      >Actions
      <button class="btn btn-sm btn-default float-right nav-icon">
        <i class="fas fa-bell"></i></button
    ></a>
    <a
      routerLink="/documents"
      routerLinkActive
      class="list-group-item list-group-item-action bg-light"
      >Documents
      <button class="btn btn-sm btn-default float-right nav-icon">
        <i class="fas fa-file-invoice"></i></button
    ></a>
    <div class="list-group-item bg-light"></div>
  </div>
</div>
