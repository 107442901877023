import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  submitting = false;
  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder) {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/send']);
    }
  }

  onSubmit(form): void {
    this.submitting = true;
    this.authService.signIn(form.email, form.password).subscribe(
      (success) => {
        this.router.navigate(['/send']);
      },
      (error) => {
        alert('Failed to sign-in');
        this.submitting = false;
      }
    );
  }
  get email(): AbstractControl {
    return this.form.controls.email;
  }
  get password(): AbstractControl {
    return this.form.controls.password;
  }
}
