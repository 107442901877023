import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  form: FormGroup;
  user;
  invalidCode = false;
  submitting = false;
  resent = false;
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    if (!authService.signupUser) {
      this.router.navigateByUrl('/');
    }
    this.user = authService.signupUser;
    //console.log(this.user);
    this.form = this.formBuilder.group({
      code: '',
    });
  }

  ngOnInit(): void {}
  onSubmit(form): void {
    this.submitting = true;
    this.invalidCode = false;
    if (this.form.dirty && this.form.valid) {
      const observable = this.authService.confirmSignUp(
        this.user.username,
        form.code
      );
      observable.subscribe(
        (results) => {
          this.router.navigateByUrl('/sign-in');
        },
        (error) => {
          if (error.code !== 'CodeMismatchException') {
            alert('Something has gone wrong!\nPlease try registering again.');
            this.router.navigateByUrl('/register');
          } else {
            this.submitting = false;
            this.invalidCode = true;
          }
        }
      );
    } else {
      this.form.markAllAsTouched();
      this.submitting = false;
      this.invalidCode = true;
    }
  }

  resendCode(): void {
    this.resent = true;
    console.log('code sent');
  }
  get code(): AbstractControl {
    return this.form.controls.code;
  }
}
