<div class="container">
  <div class="row-gap-50"></div>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <h2>Register</h2>
      <form
        *ngIf="!registerSuccess"
        [formGroup]="form"
        (ngSubmit)="onSubmit(form.value)"
      >
        <div class="form-group">
          <label for="email">Email address *</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Enter email"
            formControlName="email"
          />
          <div
            *ngIf="email.invalid && (email.dirty || email.touched)"
            class="alert alert-danger"
          >
            A valid email is required.
          </div>
        </div>
        <!-- <div class="form-group">
                    <label for="mobile">Mobile number *</label>
                    <input
                        type="tel"
                        class="form-control"
                        id="mobile"
                        placeholder="Enter Mobile Number"
                        formControlName="mobile"
                    />
                    <div
                        *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)"
                        class="alert alert-danger"
                    >
                        A valid Australian mobile number is required.
                    </div>
                </div> -->
        <div class="form-group">
          <label for="password">Password *</label>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Password"
            formControlName="password"
          />
          <div
            *ngIf="password.invalid && (password.dirty || password.touched)"
            class="alert alert-danger"
          >
            <div *ngIf="password.errors.required">Password is required.</div>
          </div>
        </div>
        <button
          [disabled]="!form.valid || registering"
          type="submit"
          class="btn btn-primary btn-block"
        >
          <ng-container *ngIf="registering"
            ><img
              class="loading_in_btn"
              src="/assets/img/loading_icon.gif" /></ng-container
          ><ng-container *ngIf="!registering">Register</ng-container>
        </button>
      </form>
    </div>
  </div>
</div>
