import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { Driver } from 'src/app/interfaces/driver.interface';

const apiName = 'tfleetApi';
const path = '/drivers';

@Injectable({
  providedIn: 'root',
})
export class DriversService {
  private drivers: Driver[];
  constructor() {}

  getDrivers(refresh: boolean = false): Promise<Driver[]> {
    if (refresh || !this.drivers) {
      return API.get(apiName, path, null)
        .then((response) => {
          this.drivers = response;
          return response;
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log(err);
          }
          if (!this.drivers) {
            this.drivers = [];
          }
          return this.drivers;
        });
    } else {
      return Promise.resolve(this.drivers);
    }
  }

  getDriver(id: string, refresh: boolean = false): Promise<Driver> {
    const itemFound = this.drivers?.filter((obj) => {
      return obj.id === id;
    })[0];

    if (refresh || !itemFound) {
      return API.get(apiName, path + '/' + id, null);
    } else {
      return Promise.resolve(itemFound);
    }
  }

  createDriver(driver: Driver): Promise<Driver> {
    if (driver.first_name?.length > 0) {
      return API.post(apiName, path, { body: driver }).then((x) => {
        if (!this.drivers) {
          this.drivers = [];
        }
        this.drivers.unshift(x);
        return x;
      });
    } else {
      return new Promise(() => {
        throw new Error('Drivers name can not be empty');
      });
    }
  }

  updateDriver(driver: Driver): Promise<Driver> {
    if (driver.first_name?.length > 0) {
      return API.put(apiName, path + '/' + driver.id, { body: driver }).then(
        (response: Driver) => {
          if (this.drivers) {
            const driverSearch = this.drivers
              .map((x) => {
                return x.id;
              })
              .indexOf(driver.id);

            if (driverSearch > -1) {
              this.drivers.splice(driverSearch, 1, response);
            } else {
              this.drivers.unshift(response);
            }
          }
          return response;
        }
      );
    } else {
      return new Promise(() => {
        throw new Error('Drivers name can not be empty');
      });
    }
  }

  deleteDriver(driver: Driver): Promise<void> {
    return API.del(apiName, path + '/' + driver.id, null).then((response) => {
      const driverSearch = this.drivers
        ?.map((x) => {
          return x.id;
        })
        .indexOf(driver.id);

      if (driverSearch > -1) {
        this.drivers.splice(driverSearch, 1);
      }
      return response;
    });
  }
}
