import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { RoutingStateService } from './services/routing-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentYear = new Date().getFullYear();
  constructor(
    routingState: RoutingStateService,
    public authService: AuthService
  ) {
    routingState.loadRouting();
  }
}
