import { Injectable } from '@angular/core';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { from, Observable } from 'rxjs';

const apiName = 'tfleetApi';
const userPath = '/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  resetPasswordEmail: string;
  signupUser: any;
  authenticatedUser: any;
  isLoggedIn: boolean;
  isLoggedIn$: Promise<boolean>;

  constructor() {
    this.refreshData();
  }

  signIn(email: string, password: string): Observable<any> {
    return from(
      Auth.signIn(email, password).then((x) => {
        this.refreshData();
        return x;
      })
    );
  }

  signOut(): Observable<any> {
    return from(
      Auth.signOut().then((x) => {
        this.clearData();
        return x;
      })
    );
  }

  signOutEverywhere(): Observable<any> {
    return from(
      Auth.signOut({ global: true }).then((x) => {
        this.clearData();
        return x;
      })
    );
  }

  signUp(email: string, password: string): Observable<ISignUpResult> {
    return from(
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      }).then((x) => {
        this.refreshData();
        return x;
      })
    );
  }

  confirmSignUp(email: string, code: string): Observable<any> {
    return from(
      Auth.confirmSignUp(email, code).then((x) => {
        this.refreshData();
        return x;
      })
    );
  }

  resendConfirmationCode(): Observable<string> {
    return from(
      Auth.resendSignUp(this.signupUser.username).then((x) => {
        this.refreshData();
        return x;
      })
    );
  }

  clearData(): void {
    this.isLoggedIn$ = Promise.resolve(false);
    this.isLoggedIn = false;
    this.authenticatedUser = undefined;
    this.signupUser = undefined;
  }

  refreshData(): void {
    this.isLoggedIn$ = Auth.currentAuthenticatedUser().then(
      (authenticatedUser) => {
        this.authenticatedUser = authenticatedUser;
        this.isLoggedIn = true;
        return true;
      },
      () => {
        this.isLoggedIn = false;
        return false;
      }
    );

    Auth.currentUserInfo().then(
      (user) => {
        this.signupUser = user;
      },
      () => {}
    );
  }

  forgotPassword(email: string): Promise<any> {
    this.resetPasswordEmail = email;
    return Auth.forgotPassword(email);
  }

  forgotPasswordSubmit(code: string, password: string): Promise<void> {
    return Auth.forgotPasswordSubmit(this.resetPasswordEmail, code, password);
  }

  changePassword(oldPassword: string, newPassword: string): Promise<'SUCCESS'> {
    return Auth.changePassword(
      this.authenticatedUser,
      oldPassword,
      newPassword
    );
  }
}

export interface User {
  id: string;
  email: string;
  mobile: string;
  creditAmount: number;
  creditExpire: string;
  timestamp: string;
}
