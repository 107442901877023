<nav class="navbar navbar-expand-md navbar-light bg-light">
  <a class="navbar-brand" routerLink="/">{{ title }}</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <ng-template [ngIf]="authService.isLoggedIn$ | async">
        <li class="nav-item d-block d-sm-none"><hr /></li>
        <li class="nav-item d-block d-sm-none">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="/"
            routerLinkActive
            >Dashboard
            <button class="btn btn-sm btn-default float-right nav-icon">
              <i class="fas fa-tachometer-alt"></i></button
          ></a>
        </li>
        <li class="nav-item d-block d-sm-none">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="/vehicles"
            routerLinkActive
            >Vehicles
            <button class="btn btn-sm btn-default float-right nav-icon">
              <i class="fas fa-truck"></i></button
          ></a>
        </li>
        <li class="nav-item d-block d-sm-none">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="/drivers"
            routerLinkActive
            >Drivers
            <button class="btn btn-sm btn-default float-right nav-icon">
              <i class="fas fa-users"></i></button
          ></a>
        </li>
        <li class="nav-item d-block d-sm-none">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="/actions"
            routerLinkActive
            >Actions
            <button class="btn btn-sm btn-default float-right nav-icon">
              <i class="fas fa-bell"></i></button
          ></a>
        </li>
        <li class="nav-item d-block d-sm-none">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="/documents"
            routerLinkActive
            >Documents
            <button class="btn btn-sm btn-default float-right nav-icon">
              <i class="fas fa-file-invoice"></i></button
          ></a>
        </li>
      </ng-template>
    </ul>
    <ul class="navbar-nav">
      <ng-template [ngIf]="authService.isLoggedIn$ | async" [ngIfElse]="login">
        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="account"
            routerLinkActive="active"
            ><span class="fas fa-user"></span> My Account</a
          >
        </li>
        <li class="nav-item"><hr /></li>
        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="sign-out"
            ><span class="fas fa-sign-out-alt"></span> Sign Out</a
          >
        </li>
      </ng-template>
      <ng-template #login>
        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="register"
            routerLinkActive="active"
            ><span class="fa fa-user"></span> Register</a
          >
        </li>
        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            class="nav-link"
            routerLink="sign-in"
            routerLinkActive="active"
            ><span class="fa fa-sign-in"></span> Sign In</a
          >
        </li>
      </ng-template>
    </ul>
  </div>
</nav>
