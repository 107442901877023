<div class="row-gap-50"></div>
<div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center">
        <h2>Confirm Email</h2>
        <p>Just a few more steps to go!</p>
        <p>
            Enter the code sent to your email below to confirm your
            email
        </p>

        <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
            <div class="form-group">
                <input
                    type="text"
                    class="form-control text-center"
                    id="code"
                    placeholder="Enter Code"
                    formControlName="code"
                    maxlength="6"
                />
            </div>

            <button
                [disabled]="!form.valid || submitting || !form.dirty"
                type="submit"
                class="btn btn-primary btn-block"
            >
                <ng-container *ngIf="submitting"
                    ><img
                        class="loading_in_btn"
                        src="/assets/img/loading_icon.gif" /></ng-container
                ><ng-container *ngIf="!submitting">Submit</ng-container>
            </button>
            <div *ngIf="invalidCode">
                <br />
                <p class="text-danger text-center">
                    <strong>Invalid Code</strong>
                </p>
                <button
                    [disabled]="resent"
                    type="button"
                    class="btn btn-default btn-block"
                    (click)="resendCode()"
                >
                    <ng-container *ngIf="resent">Code Sent...</ng-container
                    ><ng-container *ngIf="!resent">Resend Code</ng-container>
                </button>
            </div>
        </form>
    </div>
</div>
