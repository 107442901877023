<app-navbar title="TFleet"></app-navbar>
<div class="d-flex" id="wrapper">
  <app-sidebar *ngIf="authService.isLoggedIn$ | async"></app-sidebar>
  <div id="page-content-wrapper">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>

    <hr />
    <footer>
      <p class="text-center">&copy; {{ currentYear }} - Photato</p>
    </footer>
  </div>
</div>
