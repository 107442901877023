import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  submitting = false;
  email: string;
  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder) {
    this.email = authService.resetPasswordEmail;
    if (!this.email) {
      this.router.navigate(['../sign-in']);
    }

    this.form = this.fb.group({
      code: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {}
  onSubmit(form): void {
    this.submitting = true;
    this.authService.forgotPasswordSubmit(form.code, form.password).then(
      () => {
        this.router.navigate(['../sign-in']);
      },
      () => {
        alert('Invalid Verification Code');
        this.submitting = false;
      }
    );
  }
  get code() {
    return this.form.controls.code;
  }
  get password() {
    return this.form.controls.password;
  }
}
