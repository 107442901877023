<div class="text-center">
  <br />
  <h1>{{ title }}</h1>
  <br />
  <h3>{{ subtitle }}</h3>
  <br />
  <h4>{{ description }}</h4>
</div>

<div class="jumbotron text-center">
  <div
    id="steps"
    class="carousel slide"
    data-ride="carousel"
    data-interval="2000"
  >
    <!-- Indicators -->
    <ul class="carousel-indicators">
      <li data-target="#demo" data-slide-to="0" class="active"></li>
      <li data-target="#demo" data-slide-to="1"></li>
      <li data-target="#demo" data-slide-to="2"></li>
      <li data-target="#demo" data-slide-to="3"></li>
      <li data-target="#demo" data-slide-to="3"></li>
    </ul>

    <!-- The slideshow -->
    <div class="carousel-inner">
      <div class="carousel-item active">
        <h1>Vehicle registration reminders</h1>
      </div>
      <div class="carousel-item">
        <h1>Insurance reminders</h1>
      </div>
      <div class="carousel-item">
        <h1>Driver's licence expiry reminders</h1>
      </div>
    </div>
    <br />
    <!-- Left and right controls -->
    <a class="carousel-control-prev" href="#steps" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#steps" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</div>
