<div class="container">
  <div class="gap"></div>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <h2>Reset Password</h2>
      <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
        <div>
          <label for="email">Email: </label>
          <input
            id="email"
            class="form-control"
            type="email"
            disabled
            value="{{ email }}"
          />
        </div>
        <div>
          <label for="code">Verification Code: </label>
          <input
            id="code"
            class="form-control"
            type="text"
            formControlName="code"
            required
          />
          <div
            *ngIf="code.invalid && code.dirty && code.touched"
            class="alert alert-danger"
          >
            <div>Verification Code is required.</div>
          </div>
        </div>
        <div>
          <label for="password">Password: </label>
          <input
            id="password"
            class="form-control"
            formControlName="password"
            type="password"
            required
          />
          <div
            *ngIf="password.invalid && password.dirty && password.touched"
            class="alert alert-danger"
          >
            <div *ngIf="password.errors.required">Password is required.</div>
          </div>
        </div>
        <br />
        <button
          type="submit"
          [disabled]="!form.valid || submitting"
          class="btn btn-primary btn-block"
        >
          <ng-container *ngIf="submitting"
            ><img class="loading_in_btn" src="/assets/img/loading_icon.gif"
          /></ng-container>
          <ng-container *ngIf="!submitting">Reset Password</ng-container>
        </button>
        <a routerLink="../forgot-password">Back to Forgot Password</a>
      </form>
    </div>
  </div>
</div>
