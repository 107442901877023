import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { Action } from 'src/app/interfaces/action.interface';

const apiName = 'tfleetApi';
const path = '/actions';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  private actions: Action[];
  constructor() {}

  getActions(refresh: boolean = false): Promise<Action[]> {
    if (refresh || !this.actions) {
      return API.get(apiName, path, null)
        .then((response) => {
          this.actions = response;
          return this.actions.filter((x) => x.status !== 'CLOSED');
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log(err);
          }
          if (!this.actions) {
            this.actions = [];
          }
          return this.actions.filter((x) => x.status !== 'CLOSED');
        });
    } else {
      return Promise.resolve(this.actions.filter((x) => x.status !== 'CLOSED'));
    }
  }

  getAction(id: string, refresh: boolean = false): Promise<Action> {
    const itemFound = this.actions?.filter((obj) => {
      return obj.id === id;
    })[0];

    if (refresh || !itemFound) {
      return API.get(apiName, path + '/' + id, null);
    } else {
      return Promise.resolve(itemFound);
    }
  }

  createAction(action: Action): Promise<Action> {
    return API.post(apiName, path, { body: action }).then((x) => {
      if (!this.actions) {
        this.actions = [];
      }
      this.actions.unshift(x);
      return x;
    });
  }

  updateAction(action: Action): Promise<Action> {
    return API.put(apiName, path + '/' + action.id, { body: action }).then(
      (response: Action) => {
        if (this.actions) {
          const actionSearch = this.actions
            .map((x) => {
              return x.id;
            })
            .indexOf(action.id);

          if (actionSearch > -1) {
            this.actions.splice(actionSearch, 1, response);
          } else {
            this.actions.unshift(response);
          }
        }
        return response;
      }
    );
  }

  deleteAction(action: Action): Promise<void> {
    return API.del(apiName, path + '/' + action.id, null).then((response) => {
      const actionSearch = this.actions
        ?.map((x) => {
          return x.id;
        })
        .indexOf(action.id);

      if (actionSearch > -1) {
        this.actions.splice(actionSearch, 1);
      }
      return response;
    });
  }

  sortActionsByDueDate(ascending: boolean = true): void {
    if (this.actions) {
      if (ascending) {
        this.actions.sort((a, b) => {
          if (a.due_date < b.due_date) {
            return -1;
          }
          if (a.due_date > b.due_date) {
            return 1;
          }
          return 0;
        });
      } else {
        this.actions.sort((b, a) => {
          if (a.due_date < b.due_date) {
            return -1;
          }
          if (a.due_date > b.due_date) {
            return 1;
          }
          return 0;
        });
      }
    }
  }

  getDriverActions(
    driverId: string,
    refresh: boolean = false
  ): Promise<Action[]> {
    if (refresh || !this.actions) {
      return API.get(apiName, path, null)
        .then((response) => {
          this.actions = response;
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.driverId === driverId);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log(err);
          }
          if (!this.actions) {
            this.actions = [];
          }
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.driverId === driverId);
        });
    } else {
      this.sortActionsByDueDate();
      return Promise.resolve(
        this.actions
          .filter((x) => x.status !== 'CLOSED')
          .filter((x) => x.driverId === driverId)
      );
    }
  }

  getAllDriverActions(refresh: boolean = false): Promise<Action[]> {
    if (refresh || !this.actions) {
      return API.get(apiName, path, null)
        .then((response) => {
          this.actions = response;
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.driverId?.length === 36);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log(err);
          }
          if (!this.actions) {
            this.actions = [];
          }
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.driverId?.length === 36);
        });
    } else {
      this.sortActionsByDueDate();
      return Promise.resolve(
        this.actions
          .filter((x) => x.status !== 'CLOSED')
          .filter((x) => x.driverId?.length === 36)
      );
    }
  }

  getVehicleActions(
    vehicleId: string,
    refresh: boolean = false
  ): Promise<Action[]> {
    if (refresh || !this.actions) {
      return API.get(apiName, path, null)
        .then((response) => {
          this.actions = response;
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.vehicleId === vehicleId);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log(err);
          }
          if (!this.actions) {
            this.actions = [];
          }
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.vehicleId === vehicleId);
        });
    } else {
      this.sortActionsByDueDate();
      return Promise.resolve(
        this.actions
          .filter((x) => x.status !== 'CLOSED')
          .filter((x) => x.vehicleId === vehicleId)
      );
    }
  }

  getAllVehicleActions(refresh: boolean = false): Promise<Action[]> {
    if (refresh || !this.actions) {
      return API.get(apiName, path, null)
        .then((response) => {
          this.actions = response;
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.vehicleId?.length === 36);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log(err);
          }
          if (!this.actions) {
            this.actions = [];
          }
          this.sortActionsByDueDate();
          return this.actions
            .filter((x) => x.status !== 'CLOSED')
            .filter((x) => x.vehicleId?.length === 36);
        });
    } else {
      this.sortActionsByDueDate();
      return Promise.resolve(
        this.actions
          .filter((x) => x.status !== 'CLOSED')
          .filter((x) => x.vehicleId?.length === 36)
      );
    }
  }
}
