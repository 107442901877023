/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:df79e618-e33e-4509-be24-c245dbfa39b7",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_icavttZRd",
    "aws_user_pools_web_client_id": "7cmkfa8jih7p574oocqf8mdopm",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://07mb8qzvd6.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        },
        {
            "name": "tfleetApi",
            "endpoint": "https://rmoh8tadt3.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "tfleetVehicles-master",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tfleetDrivers-master",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tfleetActions-master",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "tfleet-documents90842-master",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
