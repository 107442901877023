import { Injectable } from '@angular/core';
import { Storage } from 'aws-amplify';
import { Document } from 'src/app/interfaces/document.interface';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private path = 'documents/';
  private documents: Document[];

  constructor() {}

  list(refresh: boolean = false, filepath: string = ''): Promise<Document[]> {
    if (refresh || !this.documents) {
      return Storage.list(this.path, { level: 'private' }).then((x) => {
        this.documents = x;
        if (filepath === '') {
          return Promise.resolve(this.documents);
        } else {
          return Promise.resolve(
            this.documents.filter((z) => z.key.indexOf(filepath) > -1)
          );
        }
      });
    }
    if (filepath === '') {
      return Promise.resolve(this.documents);
    } else {
      return Promise.resolve(
        this.documents.filter((z) => z.key.indexOf(filepath) > -1)
      );
    }
  }

  download(filename: string): Promise<any> {
    return Storage.get(filename, {
      level: 'private',
      download: false,
    });
  }

  upload(filename: string, file: any): Promise<any> {
    return Storage.put(this.path + filename, file, { level: 'private' });
  }

  delete(filename: string): Promise<any> {
    const index = this.documents?.findIndex((x) => x.key === filename);
    if (index >= 0) {
      this.documents.splice(index, 1);
    }
    return Storage.remove(filename, { level: 'private' });
  }
}
