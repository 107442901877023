import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { Vehicle } from 'src/app/interfaces/vehicle.interface';

const apiName = 'tfleetApi';
const path = '/vehicles';

@Injectable({
  providedIn: 'root',
  useExisting: true,
})
export class VehiclesService {
  private vehicles: Vehicle[];

  constructor() {}

  getVehicles(refresh: boolean = false): Promise<Vehicle[]> {
    if (refresh || !this.vehicles) {
      return API.get(apiName, path, null)
        .then((response) => {
          this.vehicles = response;
          return response;
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log(err);
          }
          if (!this.vehicles) {
            this.vehicles = [];
          }
          return this.vehicles;
        });
    } else {
      return Promise.resolve(this.vehicles);
    }
  }

  getVehicle(id: string, refresh: boolean = false): Promise<Vehicle> {
    const itemFound = this.vehicles?.filter((obj) => {
      return obj.id === id;
    })[0];

    if (refresh || !itemFound) {
      return API.get(apiName, path + '/' + id, null);
    } else {
      return Promise.resolve(itemFound);
    }
  }

  createVehicle(vehicle: Vehicle): Promise<Vehicle> {
    if (vehicle.registration_number?.length > 0 || vehicle.vin?.length > 0) {
      return API.post(apiName, path, { body: vehicle }).then((x) => {
        if (!this.vehicles) {
          this.vehicles = [];
        }
        this.vehicles.unshift(x);
        return x;
      });
    } else {
      return new Promise(() => {
        throw new Error('Both vehicle vin and registration can not be empty');
      });
    }
  }

  updateVehicle(vehicle: Vehicle): Promise<Vehicle> {
    if (vehicle.registration_number?.length > 0 || vehicle.vin?.length > 0) {
      return API.put(apiName, path + '/' + vehicle.id, { body: vehicle }).then(
        (response: Vehicle) => {
          if (this.vehicles) {
            const vehicleSearch = this.vehicles
              .map((x) => {
                return x.id;
              })
              .indexOf(vehicle.id);

            if (vehicleSearch > -1) {
              this.vehicles.splice(vehicleSearch, 1, response);
            } else {
              this.vehicles.unshift(response);
            }
          }
          return response;
        }
      );
    } else {
      return new Promise(() => {
        throw new Error('Both vehicle vin and registration can not be empty');
      });
    }
  }

  deleteVehicle(vehicle: Vehicle): Promise<void> {
    return API.del(apiName, path + '/' + vehicle.id, null).then((response) => {
      const vehicleSearch = this.vehicles
        ?.map((x) => {
          return x.id;
        })
        .indexOf(vehicle.id);

      if (vehicleSearch > -1) {
        this.vehicles.splice(vehicleSearch, 1);
      }
      return response;
    });
  }
}
